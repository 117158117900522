:root {
  --inline-padding: 100px;
}

@font-face {
  font-family: 'Alvi Nastaleeq';
  src: url('../fonts/alvi-nastaleeq-regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Noori Nastaleeq';
  src: url('../fonts/jameel-noori-nastaleeq-kasheeda.ttf');
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

form {
  max-width: 500px;
  margin: 0 auto;

  .title {
    text-align: center;
    margin-bottom: 50px;
  }

  .saved,
  .error {
    border: 1px solid green;
    color: green;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .error {
    border: 1px solid #dc3545;
    color: #dc3545;
  }
}

input {
  padding: 5px 10px;
  display: block;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 18px;

  &[type='submit'] {
    background-color: blue;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
}

section {
  padding: 50px var(--inline-padding);
  min-height: 50vh;

  p,
  li {
    margin-bottom: 1.75rem;
    line-height: 1.3rem;
    font-size: 1.25rem;
  }

  span {
    line-height: 1.3rem;
    font-size: 1.25rem;
  }

  h3 {
    text-align: center;
    font-size: 3rem;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  li {
    margin-inline-start: 1.5rem;
  }
}

.banner {
  width: 100%;
  height: 240px;
  background-image: url('../images/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    color: white;
    font-size: 50px;
    text-align: center;
  }
}

.min-nav {
  display: flex;
  list-style: none;
  padding: 5px var(--inline-padding);
  border-bottom: 1px solid #eeeeee;

  li {
    padding: 4px;

    a,
    .lang {
      text-decoration: none;
      color: black;
      padding: 4px 8px;
      cursor: pointer;

      &.selected {
        background-color: green;
        color: white;
      }

      &:hover:not(.selected) {
        background-color: rgba(0, 128, 0, 0.2);
      }
    }

    &.date {
      margin-inline-end: 20px;
    }

    &.nav-login {
      margin-inline-start: auto;
    }
  }
}

.logos {
  display: flex;
  padding: 30px var(--inline-padding);
  align-items: center;
  background-image: linear-gradient(45deg, transparent, yellow, transparent);

  img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
  }
}

.logo {
  flex: 1;
  text-align: center;

  h1 {
    font-size: 50px;
    color: green;
    line-height: 1em;
  }

  h2 {
    font-size: 40px;
    margin-top: 10px;
    line-height: 1em;
  }

  &.en {
    h1 {
      font-size: 45px;
      letter-spacing: -2px;
      word-spacing: -2px;
    }

    h2 {
      font-size: 35px;
      letter-spacing: -1px;
      word-spacing: -1px;
    }
  }


}

.menu {
  display: none;
}

.main-nav,
.sub-nav {
  display: flex;
  list-style: none;
  padding: 0 var(--inline-padding);
  background-color: #2e3030;

  li {
    padding: 5px;

    a {
      text-decoration: none;
      color: white;
      padding: 10px 16px;
      display: block;
      text-wrap: nowrap;
      border-radius: 8px;

      &.selected {
        background-color: green;
      }

      &:hover:not(.selected) {
        background-color: rgba(0, 128, 0, 0.2);
      }
    }

    &.button {
      margin-inline-start: auto;

      a {
        background: blue;
      }
    }
  }
}

.sub-nav {
  overflow-x: auto;
  background-color: #4f4f4f;
}

.row {
  display: flex;
  gap: 30px;

  .col {
    flex: 1;
  }
}

.contact {
  p.t {
    font-size: 20px;
    font-weight: 600;
  }

  p.d {
    font-size: 18px;
    margin: 10px 0 20px 10px;
    margin-inline-start: 10px;
  }

  iframe {
    width: 100%;
  }
}

footer {
  padding: 20px var(--inline-padding);
  text-align: center;
  background-color: black;
  color: white;
}

.news,
.results,
.admission {
  ul {
    list-style: none;
    max-width: 1000px;
    width: fit-content;
    margin: 0 auto;

    li {
      display: flex;
      gap: 10px;
      padding-bottom: 20px;
    }
  }

  .dates {
    font-size: 13px;
  }

  a {
    color: black;
    flex: 1;
    text-decoration: none;
    font-size: 18px;
  }

  .material-icons {
    margin-left: 10px;
  }
}

table {
  width: 100%;

  th,
  td {
    padding: 2px 10px;
  }

  &.personal tbody th {
    text-align: left;
  }
}

.result {
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.news-list,
.admission-list {
  margin: 0 auto;
  max-width: 800px;

  .btn {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    text-decoration: none;
    margin-left: 6px;
    background-color: blue;
    border-radius: 4px;
    color: white;
    padding: 6px;
    cursor: pointer;

    i {
      font-size: 18px;
    }
  }

  span.btn {
    background-color: #dc3545;
  }

  .new {
    background-color: green;
    display: block;
    width: max-content;
    margin-inline-start: auto;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;

    li {
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      gap: 20px;
      border-bottom: 1px solid #cccccc;

      .main {
        flex: 1;
      }
    }
  }
}

.books {
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 30px;

    img {
      width: 100%;
      aspect-ratio: 3/4;
    }

    .title {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    a {
      background-color: green;
      border-radius: 4px;
      color: white;
      display: flex;
      max-width: max-content;
      padding: 8px 12px;
      text-decoration: none;
      gap: 8px;
      margin: 0 auto;

      i {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .books .grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }
}

@media (max-width: 820px) {
  :root {
    --inline-padding: 16px;
  }

  .date,
  .nav-login,
  .language {
    display: none;
  }

  .logos {
    flex-direction: column-reverse;
    row-gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .menu {
    display: block;
    position: absolute;
    top: 8px;
    inset-inline-end: 10px;
  }

  .main-nav {
    position: absolute;
    top: -100%;
    width: calc(100% - 38px);
    display: block;
    padding: 0;
    transition: top 200ms;

    &.open {
      top: 38px;
    }

    a {
      display: block;
    }
  }

  .row {
    flex-direction: column;
  }

  .news-list,
  .admission-list {
    li {
      flex-direction: column;
    }
  }
}

@media (max-width: 500px) {
  .books .grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
}

.urdu {
  font-family: 'Alvi Nastaleeq';
  font-size: 18px;

  p,
  li {
    font-family: 'Noori Nastaleeq';
  }

  ol {
    list-style: arabic-indic;
  }
}

.english {
  font-family: sans-serif;
  font-size: 14px;

  p,
  li {
    font-family: serif;
  }
}

.ramadan-poster {
  width: 100%;
}

.bank-logo {
  width: 200px;
}